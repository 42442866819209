// React imports
import React from 'react';

// UI – style imports
import clsx from 'clsx';
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Une.style';

function Buro(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <p className={classes.title}>UNE</p>

            <p className={classes.subtitle}>Unidad Especializada de Atención a Usuarios</p>

            <Box className={classes.section}>
                <p className={classes.subtitle2}>NOMBRE</p>
                <p className={classes.text}>
                    Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de Pago Electrónico
                </p>
                <p className={classes.subtitle2}>NOMBRE DEL TITULAR DE LA UNE</p>
                <p className={classes.text}>Matin Tamizi</p>
                <p className={classes.subtitle2}>HORARIOS DE ATENCIÓN</p>
                <p className={classes.text}>Lunes a viernes de 8:00 a las 16:00 horas</p>
                <p className={classes.subtitle2}>CORREO ELECTRÓNICO</p>
                <p className={classes.text}>
                    <em>une@cuenca.com</em>
                </p>
                <p className={classes.subtitle2}>NÚMERO TELEFÓNICO</p>
                <p className={classes.text}>
                    <em>55.75.118.947</em>
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Buro);
