import { Theme, createStyles } from '@material-ui/core/styles';

// gif
import gridGif from 'images/gifs/grid-home.gif';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            color: theme.palette.primary.main,
            marginTop: '237px',
            marginLeft: '25%',
            marginBottom: '237px',
            width: '58%',
            [theme.breakpoints.up(1441)]: {
                maxWidth: '835px',
                margin: '237px auto 237px 35%',
            },
            [theme.breakpoints.down(1200)]: {
                width: '70.83%',
            },
            [theme.breakpoints.down(769)]: {
                marginLeft: '22.13%',
                marginBottom: '200px',
            },
            [theme.breakpoints.down(650)]: {
                marginTop: '150px',
                marginBottom: '175px',
            },
            [theme.breakpoints.down(501)]: {
                marginLeft: '21.85%',
                marginTop: '125px',
                marginBottom: '150px',
            },
        },
        title: {
            fontWeight: 900,
            fontSize: '59.57px',
            letterSpacing: '7px',
            marginBottom: '0px',
            [theme.breakpoints.down(900)]: {
                fontSize: '55.24px',
            },
            [theme.breakpoints.down(769)]: {
                fontSize: '51px',
                lineHeight: '122px',
                letterSpacing: '7.93333px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '50px',
                letterSpacing: '6.92px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '45px',
                letterSpacing: '4px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '29.85px',
                lineHeight: '50px',
                letterSpacing: '3.5px',
            },
        },
        subtitle: {
            fontWeight: 400,
            fontSize: '21.24px',
            lineHeight: '51.55px',
            letterSpacing: '1.5px',
            marginBottom: '110px',
            [theme.breakpoints.down(769)]: {
                fontSize: '26px',
                lineHeight: '51px',
                letterSpacing: '1.26px',
                marginBottom: '85px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '20px',
                lineHeight: '45px',
                letterSpacing: '1.31px',
                marginBottom: '75px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '15px',
                lineHeight: '40px',
                letterSpacing: '1px',
                marginBottom: '65px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '10.14px',
                lineHeight: '25.71px',
                letterSpacing: '0.75px',
                marginBottom: '50px',
            },
        },
        section: {
            marginLeft: '43%',
            [theme.breakpoints.down(900)]: { marginLeft: '0%' },
            [theme.breakpoints.down(501)]: { marginTop: '40px' },
        },
        subtitle2: {
            fontSize: '18.3px',
            lineHeight: '26px',
            fontWeight: 900,
            letterSpacing: '1px',
            marginTop: '60px',
            [theme.breakpoints.down(900)]: {
                fontSize: '21px',
                marginTop: '66px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '11px',
                lineHeight: '26px',
                marginBottom: '0px',
                marginTop: '40px',
            },
        },
        text: {
            fontSize: '18.3px',
            color: '#3D4274',
            letterSpacing: '0.78px',
            lineHeight: '26.35px',
            fontWeight: 500,
            [theme.breakpoints.down(769)]: {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '22px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '10.86px',
                letterSpacing: '0.25px',
                lineHeight: '20px',
            },
        },
    });
